import * as styles from "./plans.module.scss"

import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { useState } from "react"
import { faAngleDown, faCheck, faTimes } from "@fortawesome/pro-regular-svg-icons"
import { faMinus, faPlus } from "@fortawesome/pro-light-svg-icons"
import { faQuoteLeft, faStar } from "@fortawesome/pro-solid-svg-icons"

import { AnchorLink } from "gatsby-plugin-anchor-links"
import Brand from "../components/brand"
import Button from "../components/button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Footer from "../components/v2/footer"
import Header from "../components/v2/header"
import { LogoPlus } from "../components/logos"
import SEO from "../components/seo"
import Section from "../components/section"
import { graphql } from "gatsby"

const PlansPage = ({ data }) => {
  const testimonialImages = data.testimonialImages.edges
  const [annual, setAnnual] = useState(true)

  return (
    <>
      <SEO
        title="Pricing"
        description="Explore our pricing options and choose the plan that best fits your business needs. Interact offers flexible pricing plans to help you create engaging quizzes and drive leads for your business."
      />
      <Header hideBtn={true}/>
      <main className={ styles.plans }>
        <Section theme="white" style={ { marginTop: `4.5rem` } }>
          <div className="row justify-content-center">
            <div className="col-12 col-lg-8 text-center">
              <h1 className="heading-xl color-black">Plans for every stage of business growth</h1>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-lg-6 text-center">
              <h2 className="text-body color-muted-foreground text-center">Choose a plan to start your 14-day free trial. Create quizzes and generate leads.</h2>
              <div className={ styles.toggleContainer }>
                <div className={ styles.toggle }>
                  <button className={ !annual ? styles.active : null } onClick={ () => setAnnual(false) }>Monthly</button>
                  <button className={ annual ? styles.active : null } onClick={ () => setAnnual(true) }>Yearly</button>
                </div>
                <p className={ styles.discount }>Save up to 40%</p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center align-items-center">
            <div className="col-12 col-xl-10">
              <div className="row align-items-stretch">
                <div className="col-12 col-md-6 col-lg-4 pd-top-md-2">
                  <div className={ styles.card }>
                    <p className="text-body color-black">Lite</p>
                    <p className="text-body color-black" style={ { marginBottom: `0` } }><strong>${ annual ? '27' : '39' }</strong>/mo</p>
                    <p className="text-body color-muted-foreground" style={{ fontSize: '14px', margin: '0' }}>paid { annual ? 'annually' : 'monthly' }</p>
                    <Button theme="secondary" size="large" href={ `${process.env.GATSBY_DASHBOARD_URL}/pro/plan?id=${ annual ? 'lite_324_annual_trial' : 'lite_39_monthly_trial' }` }>Start free trial</Button>
                    <p className={ `text-body color-muted-foreground ${ styles.planDescription }` }>For new businesses</p>
                    <p className={ `text-body color-black ${ styles.checklistHeading }` }>Lite plan includes</p>
                    <ul>
                      <li>
                        <div className={ styles.check }><FontAwesomeIcon icon={ faCheck }/></div>
                        <span>{ annual ? '6k' : '500' } email leads/{ annual ? 'year' : 'month' }</span>
                      </li>
                      <li>
                        <div className={ styles.check }><FontAwesomeIcon icon={ faCheck }/></div>
                        <span>5 quizzes</span>
                      </li>
                      <li>
                        <div className={ styles.check }><FontAwesomeIcon icon={ faCheck }/></div>
                        <span>1 user</span>
                      </li>
                      <li className="d-flex d-md-none">
                        <div className={ styles.check }><FontAwesomeIcon icon={ faCheck }/></div>
                        <span>Custom design</span>
                      </li>
                      <li className="d-flex d-md-none">
                        <div className={ styles.check }><FontAwesomeIcon icon={ faCheck }/></div>
                        <span>Branching logic</span>
                      </li>
                      <li className="d-flex d-md-none">
                        <div className={ styles.check }><FontAwesomeIcon icon={ faCheck }/></div>
                        <span>Basic integrations</span>
                      </li>
                      <li className="d-flex d-md-none">
                        <div className={ styles.check }><FontAwesomeIcon icon={ faCheck }/></div>
                        <span>Basic analytics</span>
                      </li>
                      <li className="d-flex d-md-none">
                        <div className={ styles.check }><FontAwesomeIcon icon={ faCheck }/></div>
                        <span>Help docs, live chat, email support</span>
                      </li>
                      <li className="d-flex d-md-none">
                        <div className={ styles.times }><FontAwesomeIcon icon={ faTimes }/></div>
                        <span>No custom branding</span>
                      </li>
                      <li className="d-flex d-md-none">
                        <div className={ styles.times }><FontAwesomeIcon icon={ faTimes }/></div>
                        <span>No conversion tracking</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4 pd-top-md-2">
                  <div className={ styles.card } style={{ borderColor: '#c5cbff' }}>
                    <p className="text-body color-purple-500">Growth</p>
                    <p className="text-body color-black" style={ { marginBottom: `0` } }><strong>${ annual ? '53' : '89' }</strong>/mo</p>
                    <p className="text-body color-muted-foreground" style={{ fontSize: '14px', margin: '0' }}>paid { annual ? 'annually' : 'monthly' }</p>
                    <Button theme="primary" size="large" href={ `${process.env.GATSBY_DASHBOARD_URL}/pro/plan?id=${ annual ? 'growth_636_annual_trial' : 'growth_89_monthly_trial' }` }>Start free trial</Button>
                    <p className={ `text-body color-muted-foreground ${ styles.planDescription }` }>For growing companies</p>
                    <p className={ `text-body color-black ${ styles.checklistHeading }` }>Everything in Lite, and</p>
                    <ul>
                      <li>
                        <div className={ styles.check }><FontAwesomeIcon icon={ faCheck }/></div>
                        <span>{ annual ? '24k' : '2k' } email leads/{ annual ? 'year' : 'month' }</span>
                      </li>
                      <li>
                        <div className={ styles.check }><FontAwesomeIcon icon={ faCheck }/></div>
                        <span>20 quizzes</span>
                      </li>
                      <li>
                        <div className={ styles.check }><FontAwesomeIcon icon={ faCheck }/></div>
                        <span>3 users</span>
                      </li>
                      <li>
                        <div className={ styles.check }><FontAwesomeIcon icon={ faCheck }/></div>
                        <span>Custom branding</span>
                      </li>
                      <li className="d-flex d-md-none">
                        <div className={ styles.check }><FontAwesomeIcon icon={ faCheck }/></div>
                        <span>Custom design</span>
                      </li>
                      <li className="d-flex d-md-none">
                        <div className={ styles.check }><FontAwesomeIcon icon={ faCheck }/></div>
                        <span>Branching logic</span>
                      </li>
                      <li className="d-flex d-md-none">
                        <div className={ styles.check }><FontAwesomeIcon icon={ faCheck }/></div>
                        <span>Intermediate integrations</span>
                      </li>
                      <li className="d-flex d-md-none">
                        <div className={ styles.check }><FontAwesomeIcon icon={ faCheck }/></div>
                        <span>Advanced analytics</span>
                      </li>
                      <li className="d-flex d-md-none">
                        <div className={ styles.check }><FontAwesomeIcon icon={ faCheck }/></div>
                        <span>Conversion tracking</span>
                      </li>
                      <li className="d-flex d-md-none">
                        <div className={ styles.check }><FontAwesomeIcon icon={ faCheck }/></div>
                        <span>Help docs, live chat, email support</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4 pd-top-md-2">
                  <div className={ styles.card }>
                    <p className="text-body color-black">Pro</p>
                    <p className="text-body color-black" style={{ marginBottom: '0' }}><strong>${ annual ? '125' : '209' }</strong>/mo</p>
                    <p className="heading-xs color-muted-foreground" style={{ fontSize: '14px', margin: '0' }}>paid { annual ? 'annually' : 'monthly' }</p>
                    <Button theme="secondary" size="large" href={ `${process.env.GATSBY_DASHBOARD_URL}/pro/plan?id=${ annual ? 'pro_1500_annual_trial' : 'pro_209_monthly_trial' }` }>Start free trial</Button>
                    <p className={ `text-body color-muted-foreground ${ styles.planDescription }` }>For established organizations</p>
                    <p className={ `text-body color-black ${ styles.checklistHeading }` }>Everything in Growth, and</p>
                    <ul>
                      <li>
                        <div className={ styles.check }><FontAwesomeIcon icon={ faCheck }/></div>
                        <span>{ annual ? '96k' : '8k' } email leads/{ annual ? 'year' : 'month' }</span>
                      </li>
                      <li>
                        <div className={ styles.check }><FontAwesomeIcon icon={ faCheck }/></div>
                        <span>50 quizzes</span>
                      </li>
                      <li>
                        <div className={ styles.check }><FontAwesomeIcon icon={ faCheck }/></div>
                        <span>10 users</span>
                      </li>
                      <li>
                        <div className={ styles.check }><FontAwesomeIcon icon={ faCheck }/></div>
                        <span>Custom branding</span>
                      </li>
                      <li>
                        <div className={ styles.check }><FontAwesomeIcon icon={ faCheck }/></div>
                        <span>VIP quiz support</span>
                      </li>
                      <li className="d-flex d-md-none">
                        <div className={ styles.check }><FontAwesomeIcon icon={ faCheck }/></div>
                        <span>Custom design</span>
                      </li>
                      <li className="d-flex d-md-none">
                        <div className={ styles.check }><FontAwesomeIcon icon={ faCheck }/></div>
                        <span>Branching logic</span>
                      </li>
                      <li className="d-flex d-md-none">
                        <div className={ styles.check }><FontAwesomeIcon icon={ faCheck }/></div>
                        <span>Advanced integrations</span>
                      </li>
                      <li className="d-flex d-md-none">
                        <div className={ styles.check }><FontAwesomeIcon icon={ faCheck }/></div>
                        <span>Advanced analytics</span>
                      </li>
                      <li className="d-flex d-md-none">
                        <div className={ styles.check }><FontAwesomeIcon icon={ faCheck }/></div>
                        <span>Conversion tracking</span>
                      </li>
                      <li className="d-flex d-md-none">
                        <div className={ styles.check }><FontAwesomeIcon icon={ faCheck }/></div>
                        <span>Help docs, live chat, email support</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-auto">
              <AnchorLink to="/plans/#compare" className={styles.compareLink}>Show all features <FontAwesomeIcon icon={faAngleDown}/></AnchorLink>
            </div>
          </div>
        </Section>
        <Section theme="white" style={{ padding: '2rem 0 2rem 0' }}>
          <div className={ `row no-gutters justify-content-center align-items-center ${ styles.interactPlus }` }>
            <div className="col-12 col-lg-5 text-md-center">
              <div className={ styles.interactPlusContent }>
                <LogoPlus style={{ height: '32px', width: '164px' }} color="#ffffff" />
                <h3 className="heading-lg color-primary-950" style={{ marginTop: '1rem' }}>Full service quiz strategy + implementation for scaled brands</h3>
                <p className="text-body color-primary-100">Work directly with a quiz expert from Interact to implement a full quiz strategy for your business.</p>
                <Button theme="primary" type="internal" href="/plus/" style={ { marginTop: `1rem` } }>Inquire now</Button>
              </div>
            </div>
            <div className="col-12 col-lg-7">
              <div className={ styles.interactPlusTestimonial }>
                <p className="heading-xs-upper color-primary-100" style={{ fontWeight: '700', fontSize: '14px', marginBottom: '1.5rem' }}>Client testimonial</p>
                <div className={ styles.quote }>
                  <p className="text-body color-white text-center" style={{ fontSize: '14px' }}>"Working with the Interact Plus team is so powerful because they understand our long term goals to keep building out discoverability though multiple quizzes that bring to life all the content we have on site. The quizzes we have on site continuously help us grow our audience year over year. The higher quality the quiz and the more strategic thought put in with this team means we are making a powerful growth investment every time we partner."</p>
                  <div className={styles.testimonialBy}>
                    <GatsbyImage image={ getImage(testimonialImages[1].node) } alt="Anne Mercogliano" style={ { borderRadius: `50%`, height: '72px', width: '72px' } }/>
                    <div className={styles.testimonialName}>
                      <p className="text-body color-white">Anne Mercogliano</p>
                      <p className="text-body color-primary-100">CEO, Gretchen Rubin Media</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Section>
        <Section theme="white" className="d-none d-md-block">
          <div id="compare" className="row no-gutters">
            <div className="col-4 col-lg-5 d-none d-md-block">
              <div className={ styles.pricingTableItem } style={{ alignItems: 'flex-start' }}>
                <p className="heading-sm color-black"><strong>Compare Plans</strong></p>
              </div>
            </div>
            <div className="col-12 col-md-8 col-lg-7">
              <div className="row no-gutters">
                <div className="col-4">
                  <div className={ styles.pricingTableItem }>
                    <p className="heading-sm color-black"><strong>Lite</strong></p>
                  </div>
                </div>
                <div className="col-4">
                  <div className={ styles.pricingTableItem }>
                    <p className="heading-sm color-black"><strong>Growth</strong></p>
                  </div>
                </div>
                <div className="col-4">
                  <div className={ styles.pricingTableItem }>
                    <p className="heading-sm color-black"><strong>Pro</strong></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row no-gutters" style={ { borderTop: `1px solid rgb(229 231 235)`  } }>
            <div className="col-4 col-lg-5 d-none d-md-block">
              <div className={ styles.pricingTableItem } style={{ alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                <p className="text-body color-black" style={ { fontWeight: '500' } }>Number of Quizzes</p>
                <p className="text-body color-muted-foreground" style={ { fontSize: `14px` } }>Create, publish, and share your quiz all over the internet</p>
              </div>
            </div>
            <div className="col-12 col-md-8 col-lg-7">
              <div className="row no-gutters" style={ { height: `100%` } }>
                <div className="col-4">
                  <div className={ styles.pricingTableItem }>
                    <p className="text-body color-black d-none d-md-block" style={ { fontSize: `14px` } }>5</p>
                    <p className="text-body color-black text-center d-block d-md-none"><strong>5 quizzes</strong></p>
                  </div>
                </div>
                <div className="col-4">
                  <div className={ styles.pricingTableItem }>
                    <p className="text-body color-black d-none d-md-block" style={ { fontSize: `14px` } }>20</p>
                    <p className="text-body color-black text-center d-block d-md-none"><strong>20 quizzes</strong></p>
                  </div>
                </div>
                <div className="col-4">
                  <div className={ styles.pricingTableItem }>
                    <p className="text-body color-black d-none d-md-block" style={ { fontSize: `14px` } }>50</p>
                    <p className="text-body color-black text-center d-block d-md-none"><strong>50 quizzes</strong></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row no-gutters" style={ { borderTop: `1px solid rgb(229 231 235)`  } }>
            <div className="col-4 col-lg-5 d-none d-md-block">
              <div className={ styles.pricingTableItem } style={{ alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                <p className="text-body color-black" style={ { fontWeight: '500' } }>Number of email leads</p>
                <p className="text-body color-muted-foreground" style={ { fontSize: `14px` } }>Capture qualified leads who are already excited and engaged by you and your brand</p>
              </div>
            </div>
            <div className="col-12 col-md-8 col-lg-7">
              <div className="row no-gutters" style={ { height: `100%` } }>
                <div className="col-4">
                  <div className={ styles.pricingTableItem }>
                    <p className="text-body color-black d-none d-md-block" style={ { fontSize: `14px` } }>{ annual ? '6,000' : '500' }/{ annual ? 'year' : 'month' }</p>
                    <p className="text-body color-black text-center d-block d-md-none" style={ { fontSize: `1rem` } }>Up to <strong>{ annual ? '6k' : '500' } email leads</strong> a { annual ? 'year' : 'month' }</p>
                  </div>
                </div>
                <div className="col-4">
                  <div className={ styles.pricingTableItem }>
                    <p className="text-body color-black d-none d-md-block" style={ { fontSize: `14px` } }>{ annual ? '24,000' : '2,000' }/{ annual ? 'year' : 'month' }</p>
                    <p className="text-body color-black text-center d-block d-md-none" style={ { fontSize: `1rem` } }>Up to <strong>{ annual ? '24k' : '2k' } email leads</strong> a { annual ? 'year' : 'month' }</p>
                  </div>
                </div>
                <div className="col-4">
                  <div className={ styles.pricingTableItem }>
                    <p className="text-body color-black d-none d-md-block" style={ { fontSize: `14px` } }>{ annual ? '96,000' : '8,000' }/{ annual ? 'year' : 'month' }</p>
                    <p className="text-body color-black text-center d-block d-md-none" style={ { fontSize: `1rem` } }>Up to <strong>{ annual ? '96k' : '8k' } email leads</strong> a { annual ? 'year' : 'month' }</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row no-gutters" style={ { borderTop: `1px solid rgb(229 231 235)`  } }>
            <div className="col-4 col-lg-5 d-none d-md-block">
              <div className={ styles.pricingTableItem } style={{ alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                <p className="text-body color-black" style={ { fontWeight: '500' } }>Custom design</p>
                <p className="text-body color-muted-foreground" style={ { fontSize: `14px` } }>Tailor your quiz to fit your brand</p>
              </div>
            </div>
            <div className="col-12 col-md-8 col-lg-7">
              <div className="row no-gutters" style={ { height: `100%` } }>
                <div className="col-4">
                  <div className={ styles.pricingTableItem }>
                    <p className="text-body color-pink d-none d-md-block"><FontAwesomeIcon icon={ faCheck }/></p>
                    <p className="text-body color-black text-center d-block d-md-none" style={ { fontSize: `1rem` } }><strong>Custom design</strong></p>
                  </div>
                </div>
                <div className="col-4">
                  <div className={ styles.pricingTableItem }>
                    <p className="text-body color-pink d-none d-md-block"><FontAwesomeIcon icon={ faCheck }/></p>
                    <p className="text-body color-black text-center d-block d-md-none" style={ { fontSize: `1rem` } }><strong>Custom design</strong></p>
                  </div>
                </div>
                <div className="col-4">
                  <div className={ styles.pricingTableItem }>
                    <p className="text-body color-pink d-none d-md-block"><FontAwesomeIcon icon={ faCheck }/></p>
                    <p className="text-body color-black text-center d-block d-md-none" style={ { fontSize: `1rem` } }><strong>Custom design</strong></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row no-gutters" style={ { borderTop: `1px solid rgb(229 231 235)`  } }>
            <div className="col-4 col-lg-5 d-none d-md-block">
              <div className={ styles.pricingTableItem } style={{ alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                <p className="text-body color-black" style={ { fontWeight: '500' } }>Custom Branding</p>
                <p className="text-body color-muted-foreground" style={ { fontSize: `14px` } }>Replace Interact logo with your own branding</p>
              </div>
            </div>
            <div className="col-12 col-md-8 col-lg-7">
              <div className="row no-gutters" style={ { height: `100%` } }>
                <div className="col-4">
                  <div className={ styles.pricingTableItem }>
                    <p className="heading-lg color-blue-50 d-none d-md-block">&nbsp;</p>
                    <p className="heading-sm color-black text-center d-block d-md-none" style={ { fontSize: `1rem` } }>No custom branding</p>
                  </div>
                </div>
                <div className="col-4">
                  <div className={ styles.pricingTableItem }>
                    <p className="text-body color-pink d-none d-md-block"><FontAwesomeIcon icon={ faCheck }/></p>
                    <p className="text-body color-black text-center d-block d-md-none" style={ { fontSize: `1rem` } }><strong>Custom branding</strong></p>
                  </div>
                </div>
                <div className="col-4">
                  <div className={ styles.pricingTableItem }>
                    <p className="text-body color-pink d-none d-md-block"><FontAwesomeIcon icon={ faCheck }/></p>
                    <p className="text-body color-black text-center d-block d-md-none" style={ { fontSize: `1rem` } }><strong>Custom branding</strong></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row no-gutters" style={ { borderTop: `1px solid rgb(229 231 235)`  } }>
            <div className="col-4 col-lg-5 d-none d-md-block">
              <div className={ styles.pricingTableItem } style={{ alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                <p className="text-body color-black" style={ { fontWeight: '500' } }>Branching Logic</p>
                <p className="text-body color-muted-foreground" style={ { fontSize: `14px` } }>Get super specific by asking users different questions depending on how they answer</p>
              </div>
            </div>
            <div className="col-12 col-md-8 col-lg-7">
              <div className="row no-gutters" style={ { height: `100%` } }>
                <div className="col-4">
                  <div className={ styles.pricingTableItem }>
                    <p className="text-body color-pink d-none d-md-block"><FontAwesomeIcon icon={ faCheck }/></p>
                    <p className="text-body color-black text-center d-block d-md-none" style={ { fontSize: `1rem` } }><strong>Branching logic</strong></p>
                  </div>
                </div>
                <div className="col-4">
                  <div className={ styles.pricingTableItem }>
                    <p className="text-body color-pink d-none d-md-block"><FontAwesomeIcon icon={ faCheck }/></p>
                    <p className="text-body color-black text-center d-block d-md-none" style={ { fontSize: `1rem` } }><strong>Branching logic</strong></p>
                  </div>
                </div>
                <div className="col-4">
                  <div className={ styles.pricingTableItem }>
                    <p className="text-body color-pink d-none d-md-block"><FontAwesomeIcon icon={ faCheck }/></p>
                    <p className="text-body color-black text-center d-block d-md-none" style={ { fontSize: `1rem` } }><strong>Branching logic</strong></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row no-gutters" style={ { borderTop: `1px solid rgb(229 231 235)`  } }>
            <div className="col-4 col-lg-5 d-none d-md-block">
              <div className={ styles.pricingTableItem } style={{ alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                <p className="text-body color-black" style={ { fontWeight: '500' } }>Result Page CTA</p>
                <p className="text-body color-muted-foreground" style={ { fontSize: `14px` } }>Send visitors to the next step in your quiz full with call-to-action buttons</p>
              </div>
            </div>
            <div className="col-12 col-md-8 col-lg-7">
              <div className="row no-gutters" style={ { height: `100%` } }>
                <div className="col-4">
                  <div className={ styles.pricingTableItem }>
                    <p className="text-body color-pink d-none d-md-block"><FontAwesomeIcon icon={ faCheck }/></p>
                    <p className="text-body color-black text-center d-block d-md-none" style={ { fontSize: `1rem` } }><strong>Result page CTAs</strong></p>
                  </div>
                </div>
                <div className="col-4">
                  <div className={ styles.pricingTableItem }>
                    <p className="text-body color-pink d-none d-md-block"><FontAwesomeIcon icon={ faCheck }/></p>
                    <p className="text-body color-black text-center d-block d-md-none" style={ { fontSize: `1rem` } }><strong>Result page CTAs</strong></p>
                  </div>
                </div>
                <div className="col-4">
                  <div className={ styles.pricingTableItem }>
                    <p className="text-body color-pink d-none d-md-block"><FontAwesomeIcon icon={ faCheck }/></p>
                    <p className="text-body color-black text-center d-block d-md-none" style={ { fontSize: `1rem` } }><strong>Result page CTAs</strong></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row no-gutters" style={ { borderTop: `1px solid rgb(229 231 235)`  } }>
            <div className="col-4 col-lg-5 d-none d-md-block">
              <div className={ styles.pricingTableItem } style={{ alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                <p className="text-body color-black" style={ { fontWeight: '500' } }>Landing Page Redirect</p>
                <p className="text-body color-muted-foreground" style={ { fontSize: `14px` } }>Send visitors to your own result landing pages</p>
              </div>
            </div>
            <div className="col-12 col-md-8 col-lg-7">
              <div className="row no-gutters" style={ { height: `100%` } }>
                <div className="col-4">
                  <div className={ styles.pricingTableItem }>
                    <p className="text-body color-pink d-none d-md-block"><FontAwesomeIcon icon={ faCheck }/></p>
                    <p className="heading-sm color-blue text-center d-block d-md-none" style={ { fontSize: `1rem` } }><strong>Landing page redirects</strong></p>
                  </div>
                </div>
                <div className="col-4">
                  <div className={ styles.pricingTableItem }>
                    <p className="text-body color-pink d-none d-md-block"><FontAwesomeIcon icon={ faCheck }/></p>
                    <p className="heading-sm color-blue text-center d-block d-md-none" style={ { fontSize: `1rem` } }><strong>Landing page redirects</strong></p>
                  </div>
                </div>
                <div className="col-4">
                  <div className={ styles.pricingTableItem }>
                    <p className="text-body color-pink d-none d-md-block"><FontAwesomeIcon icon={ faCheck }/></p>
                    <p className="heading-sm color-blue text-center d-block d-md-none" style={ { fontSize: `1rem` } }><strong>Landing page redirects</strong></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row no-gutters" style={ { borderTop: `1px solid rgb(229 231 235)`  } }>
            <div className="col-4 col-lg-5 d-none d-md-block">
              <div className={ styles.pricingTableItem } style={{ alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                <p className="text-body color-black" style={ { fontWeight: '500' } }>Integrations</p>
                <p className="text-body color-muted-foreground" style={ { fontSize: `14px` } }>Easily embed your quiz on your website and seamlessly integrate your quiz with the rest of your marketing platforms</p>
              </div>
            </div>
            <div className="col-12 col-md-8 col-lg-7">
              <div className="row no-gutters" style={ { height: `100%` } }>
                <div className="col-4 text-center">
                  <div className={ styles.pricingTableItem } style={ { position: `relative` } }>
                    <p className="text-body color-black" style={ { fontSize: `14px` } }>Basic Integrations:</p>
                    <p className="text-body color-muted-foreground" style={ { fontSize: `14px` } }>ActiveCampaign, AWeber, Campaign Monitor, Constant Contact, ConvertKit, dotdigital, Drip, Flodesk, GetResponse, Kartra, Klaviyo, MadMimi, Mailchimp, MailerLite, SendGrid, Thinkific, and Zapier</p>
                  </div>
                </div>
                <div className="col-4 text-center">
                  <div className={ styles.pricingTableItem } style={{ justifyContent: 'flex-start' }}>
                    <p className="text-body color-black" style={ { fontSize: `14px` } }>Advanced Integrations:</p>
                    <p className="text-body color-muted-foreground" style={ { fontSize: `14px` } }><em>Basic Integrations</em> + HubSpot, Infusionsoft, Maropost, and Ontraport</p>
                  </div>
                </div>
                <div className="col-4 text-center">
                  <div className={ styles.pricingTableItem } style={{ justifyContent: 'flex-start' }}>
                    <p className="text-body color-black" style={ { fontSize: `14px` } }>Advanced Integrations:</p>
                    <p className="text-body color-muted-foreground" style={ { fontSize: `14px` } }><em>Basic Integrations</em> + HubSpot, Infusionsoft, Maropost, and Ontraport</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row no-gutters" style={ { borderTop: `1px solid rgb(229 231 235)`  } }>
            <div className="col-4 col-lg-5 d-none d-md-block">
              <div className={ styles.pricingTableItem } style={{ alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                <p className="text-body color-black" style={ { fontWeight: '500' } }>Conversion Tracking</p>
                <p className="text-body color-muted-foreground" style={ { fontSize: `14px` } }>Get specific data on where your quiz takers are coming from with your Google Analytics</p>
              </div>
            </div>
            <div className="col-12 col-md-8 col-lg-7">
              <div className="row no-gutters" style={ { height: `100%` } }>
                <div className="col-4">
                  <div className={ styles.pricingTableItem }>
                    <p className="text-body color-pink d-none d-md-block">&nbsp;</p>
                    <p className="heading-sm color-blue text-center d-block d-md-none" style={ { fontSize: `1rem` } }>No conversion tracking</p>
                  </div>
                </div>
                <div className="col-4">
                  <div className={ styles.pricingTableItem }>
                    <p className="text-body color-pink d-none d-md-block"><FontAwesomeIcon icon={ faCheck }/></p>
                    <p className="heading-sm color-blue text-center d-block d-md-none" style={ { fontSize: `1rem` } }><strong>Conversion Tracking:</strong> Google Analytics</p>
                  </div>
                </div>
                <div className="col-4">
                  <div className={ styles.pricingTableItem }>
                    <p className="text-body color-pink d-none d-md-block"><FontAwesomeIcon icon={ faCheck }/></p>
                    <p className="heading-sm color-blue text-center d-block d-md-none" style={ { fontSize: `1rem` } }><strong>Conversion Tracking:</strong> Google Analytics</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row no-gutters" style={ { borderTop: `1px solid rgb(229 231 235)`  } }>
            <div className="col-4 col-lg-5 d-none d-md-block">
              <div className={ styles.pricingTableItem } style={{ alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                <p className="text-body color-black" style={ { fontWeight: '500' } }>Analytics</p>
                <p className="text-body color-muted-foreground" style={ { fontSize: `14px` } }>Learn about your audience and get clear on how your quiz is performing</p>
              </div>
            </div>
            <div className="col-12 col-md-8 col-lg-7">
              <div className="row no-gutters" style={ { height: `100%` } }>
                <div className="col-4 text-center">
                  <div className={ styles.pricingTableItem } style={{ justifyContent: 'flex-start' }}>
                    <p className="text-body color-black" style={ { fontSize: `14px` } }>Basic Analytics:</p>
                    <p className="text-body color-muted-foreground" style={ { fontSize: `14px` } }>See how your quiz is performing with a bird&quot;s eye overview</p>
                  </div>
                </div>
                <div className="col-4 text-center">
                  <div className={ styles.pricingTableItem } style={{ justifyContent: 'flex-start' }}>
                    <p className="text-body color-black" style={ { fontSize: `14px` } }>Advanced Analytics:</p>
                    <p className="text-body color-muted-foreground" style={ { fontSize: `14px` } }>Optimize your quiz performance with aggregated audience data and drop-off graphs</p>
                  </div>
                </div>
                <div className="col-4 text-center">
                  <div className={ styles.pricingTableItem } style={{ justifyContent: 'flex-start' }}>
                    <p className="text-body color-black" style={ { fontSize: `14px` } }>Advanced Analytics:</p>
                    <p className="text-body color-muted-foreground" style={ { fontSize: `14px` } }>Optimize your quiz performance with aggregated audience data and drop-off graphs</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row no-gutters" style={ { borderTop: `1px solid rgb(229 231 235)`  } }>
            <div className="col-4 col-lg-5 d-none d-md-block">
              <div className={ styles.pricingTableItem } style={{ alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                <p className="text-body color-black" style={ { fontWeight: '500' } }>Additional Team Members</p>
                <p className="text-body color-muted-foreground" style={ { fontSize: `14px` } }>The number of team members you can invite to join your account</p>
              </div>
            </div>
            <div className="col-12 col-md-8 col-lg-7">
              <div className="row no-gutters" style={ { height: `100%` } }>
                <div className="col-4">
                  <div className={ styles.pricingTableItem }>
                    <p className="text-body color-pink d-none d-md-block">&nbsp;</p>
                    <p className="heading-sm color-blue text-center d-block d-md-none" style={ { fontSize: `1rem` } }>No team invites</p>
                  </div>
                </div>
                <div className="col-4">
                  <div className={ styles.pricingTableItem }>
                    <p className="text-body color-black d-none d-md-block" style={ { fontSize: `14px` } }>2</p>
                    <p className="heading-sm color-blue text-center d-block d-md-none" style={ { fontSize: `1rem` } }>2 team members</p>
                  </div>
                </div>
                <div className="col-4">
                  <div className={ styles.pricingTableItem }>
                    <p className="text-body color-black d-none d-md-block" style={ { fontSize: `14px` } }>9</p>
                    <p className="heading-sm color-blue text-center d-block d-md-none" style={ { fontSize: `1rem` } }>9 team members</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row no-gutters" style={ { borderTop: '1px solid rgb(229 231 235)', borderBottom: '1px solid rgb(229 231 235)' } }>
            <div className="col-4 col-lg-5 d-none d-md-block">
              <div className={ styles.pricingTableItem } style={{ alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                <p className="text-body color-black" style={ { fontWeight: '500' } }>Support</p>
                <p className="text-body color-muted-foreground" style={ { fontSize: `14px` } }>Get all the help you need to build your list with a quiz</p>
              </div>
            </div>
            <div className="col-12 col-md-8 col-lg-7">
              <div className="row no-gutters" style={ { height: `100%` } }>
                <div className="col-4 text-center">
                  <div className={ styles.pricingTableItem }>
                    <p className="text-body color-black" style={ { fontSize: `14px` } }>Help Docs, Live Chat + Email</p>
                  </div>
                </div>
                <div className="col-4 text-center">
                  <div className={ styles.pricingTableItem }>
                    <p className="text-body color-black" style={ { fontSize: `14px` } }>Help Docs, Live Chat + Email</p>
                  </div>
                </div>
                <div className="col-4 text-center">
                  <div className={ styles.pricingTableItem }>
                    <p className="text-body color-black" style={ { fontSize: `14px` } }>Help Docs, Live Chat, + Email</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Section>
        <Section theme="default" style={ { padding: `2rem 0 5rem 0` } }>
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-8 text-center">
              <p className="heading-xl color-black">Interact quizzes help businesses grow</p>
            </div>
          </div>
          <div className="row justify-content-center" style={{ marginTop: '3rem' }}>
            <div className="col-12 col-lg-10 col-xl-8">
              <div className={ styles.testimonial }>
                <FontAwesomeIcon icon={ faStar } className={ styles.quote }/>
                <FontAwesomeIcon icon={ faStar } className={ styles.quote }/>
                <FontAwesomeIcon icon={ faStar } className={ styles.quote }/>
                <FontAwesomeIcon icon={ faStar } className={ styles.quote }/>
                <FontAwesomeIcon icon={ faStar } className={ styles.quote }/>
                <p className="text-body color-black">Well, since my own email list is growing by 5,000 subscribers each and every week, I can confidently say that having a fun quiz plays a big role in generating these kind of numbers.<br/><br/>My quiz has also been one of the biggest contributors in increasing my unpaid email list growth, which I love!</p>
                <div className={ styles.caption }>
                  <GatsbyImage image={ getImage(testimonialImages[9].node) } alt="Jenna Kutcher" style={ { height: '72px', width: '72px', borderRadius: `50%`, transform: `translateZ(0)` } }/>
                  <p className="text-body color-black" style={{ marginTop: '0.5rem' }}>Jenna Kutcher</p>
                  <p className="text-body">NYT Best-Selling Author</p>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-10 col-xl-8">
              <div className={ styles.testimonial }>
                <FontAwesomeIcon icon={ faStar } className={ styles.quote }/>
                <FontAwesomeIcon icon={ faStar } className={ styles.quote }/>
                <FontAwesomeIcon icon={ faStar } className={ styles.quote }/>
                <FontAwesomeIcon icon={ faStar } className={ styles.quote }/>
                <FontAwesomeIcon icon={ faStar } className={ styles.quote }/>
                <p className="text-body color-black">On the custom website design side of my business, I created an Interact quiz almost two years ago for one of my previous marketing clients. That quiz has generated over 10,000 new email subscribers in the past two years! Their quiz has a 58% percent completion rate.<br/><br/>Plus, the subscribers who come from their quiz are always very engaged and tend to open emails at a higher rate than other subscribers. The same is true for my own business.</p>
                <div className={ styles.caption }>
                  <GatsbyImage image={ getImage(testimonialImages[8].node) } alt="Elizabeth McCravy" style={ { height: '72px', width: '72px', borderRadius: `50%`, transform: `translateZ(0)` } }/>
                  <p className="text-body color-black" style={{ marginTop: '0.5rem' }}>Elizabeth McCravy</p>
                  <p className="text-body">ShowIt Website Designer</p>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-10 col-xl-8">
              <div className={ styles.testimonial }>
                <FontAwesomeIcon icon={ faStar } className={ styles.quote }/>
                <FontAwesomeIcon icon={ faStar } className={ styles.quote }/>
                <FontAwesomeIcon icon={ faStar } className={ styles.quote }/>
                <FontAwesomeIcon icon={ faStar } className={ styles.quote }/>
                <FontAwesomeIcon icon={ faStar } className={ styles.quote }/>
                <p className="text-body color-black">Quizzes are an amazing way to convert people. You get to know so much about an individual person, or even about your people, and the trends that exist within your audience.</p>
                <div className={ styles.caption }>
                  <GatsbyImage image={ getImage(testimonialImages[7].node) } alt="Minessa Konecky" style={ { height: '72px', width: '72px', borderRadius: `50%`, transform: `translateZ(0)` } }/>
                  <p className="text-body color-black" style={{ marginTop: '0.5rem' }}>Minessa Konecky</p>
                  <p className="text-body">CEO, Research Administration Expert</p>
                </div>
              </div>
            </div>
          </div>
        </Section>
        <Section theme="default" style={ { padding: `2rem 0 5rem 0` } }>
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-12 col-xl-10">
              <div className="row">
                <div className="col">
                  <p className="text-body color-black text-center">Trusted by leading businesses</p>
                </div>
              </div>
              <div className="row align-items-center justify-content-center justify-content-lg-between">
                <Brand id="gretchen-rubin" className={ `col-auto ${ styles.brand }` }/>
                <Brand id="jennakutcher-color" className={ `col-auto ${ styles.brand }` }/>
                <Brand id="melyssagriffin-color" className={ `col-auto ${ styles.brand }` }/>
                <Brand id="redcross-color" className={ `col-auto ${ styles.brand }` }/>
                <Brand id="atlassian" className={ `col-auto ${ styles.brand }` }/>
                <Brand id="lush-color" className={ `col-auto ${ styles.brand }` }/>
              </div>
            </div>
          </div>
        </Section>
        <Section theme="default" style={ { padding: `2rem 0 5rem 0` } }>
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-8 text-center">
              <p className="heading-xl color-black">Frequently asked questions</p>
            </div>
          </div>
          <div className="row justify-content-center" style={{ marginTop: '3rem' }}>
            <div className="col-lg-10 col-xl-8">
              <FAQCard title={ `I've never written a quiz before, is it hard?` }>
                <p className="text-body color-black">No it's not anymore. With Interact's AI Quiz Maker you can generate a quiz from your website or any piece of content in 60 seconds.</p>
              </FAQCard>
              <FAQCard title={ `What's the best way to get started?` }>
                <p className="text-body color-black">Your 14-day free trial gets you access to dozens of customizable conversion focused templates that you can easily personalize to match your brand and offers.</p>
                <p className="text-body color-black">But if you've got questions you want answered before your sign up, <a href="https://info.tryinteract.com/demo" className="color-pink">click here to book a demo and strategy call</a>.</p>
                <p className="text-body color-black">We've got you covered.</p>
              </FAQCard>
              <FAQCard title={ `Do I have to be tech-saavy in order to build a quiz?` }>
                <p className="text-body color-black">Not at all. Our in-app support will walk you through anything you need to know.</p>
              </FAQCard>
              <FAQCard title={ `Do you integrate with my email marketing platform?` }>
                <p className="text-body color-black">Yes! We have native integrations with most major CRMs and email marketing platforms. And we've got Zapier to fill in any gaps.</p>
              </FAQCard>
              <FAQCard title={ `Can I customize my quiz to match my brand?` }>
                <p className="text-body color-black">For sure! You can match your brand fonts and color scheme throughout the quiz.</p>
                <p className="text-body color-black">Plus, you can white label your quiz with our Growth and Pro plans.</p>
              </FAQCard>
              <FAQCard title={ `Can I add team members to my account?` }>
                <p className="text-body color-black">Yes, as long as you're subscribed to our Growth or Pro plans.</p>
                <p className="text-body color-black">Once you're logged in, hit your account name in the top right corner, then select Account.</p>
                <p className="text-body color-black">Select Team and then hit + New Team Member.</p>
                <p className="text-body color-black">And don't worry, you get to decide who can see the accounting tab.</p>
              </FAQCard>
              <FAQCard title={ `What if I want to cancel my subscription?` }>
                <p className="text-body color-black">Just click “cancel” in the app. There are no hoops to jump through.</p>
                <p className="text-body color-black">You will not be charged until your trial ends in 14 days.</p>
                <p className="text-body color-black">We'll send you an email reminder a few days before it does.</p>
                <p className="text-body color-black">And if you forget to cancel and get charged, we'll refund you no questions asked.</p>
              </FAQCard>
            </div>
          </div>
        </Section>
        <Section theme="purple500" style={ { padding: `5rem 0` } }>
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-8 text-center">
              <p className="heading-xl color-white">Get started today</p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-8 col-xl-6 text-center">
              <p className="heading-sm color-white text-center" style={{ marginTop: '0', marginBottom: '2rem' }}>Use our AI quiz maker to create a beautiful quiz for your brand in a minute.</p>
              <Button theme="white" size="large" href="https://ai.tryinteract.com/create">Make a quiz - for free</Button>
            </div>
          </div>
        </Section>
      </main>
      <Footer/>
    </>
  )
}

export default PlansPage

const FAQCard = (props) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <button className={ styles.faqBtn } onClick={ () => setOpen(!open) }>
        <span>{ props.title }</span>
        { !open ? <FontAwesomeIcon icon={ faPlus } /> : <FontAwesomeIcon icon={ faMinus } /> }
      </button>
      <div className={ [styles.collapse, (open ? styles.show : '')].join(' ') }>
        <div className={ styles.content }>{ props.children }</div>
      </div>
    </>
  )
}

export const query = graphql`
  query {
    testimonialImages: allFile(filter: {extension: {regex: "/(jpg)|(png)|(jpeg)/"}, relativeDirectory: {eq: "testimonials"}}, sort: {fields: relativePath}) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData (
              layout: FIXED,
              width: 150,
              height: 150,
              quality: 100,
            )
          }
        }
      }
    }
  }
`